/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiKeyInterceptor } from './apikey-interceptor';
//import { DateInterceptor } from './date-interceptor';
//import { JwtInterceptor } from './jwt-interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ApiKeyInterceptor, multi: true }//,
  //{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  //{ provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true }
];
