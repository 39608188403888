import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Login', url: '/folder/View1', icon: 'log-in' },
    { title: 'Einzahlen', url: '/folder/View2', icon: 'arrow-undo' },
    { title: 'Aktueller Stand Kassa', url: '/folder/View3', icon: 'barcode' },
    { title: 'Protokoll der Kassa', url: '/folder/View4', icon: 'analytics' },
    { title: 'Auszahlung Geld', url: '/folder/View5', icon: 'aperture' },
  ];
  // public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor() {
    console.log("test")
  }
}
