import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then(m => m.FolderPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'card-detail',
    loadChildren: () => import('./pages/card-detail/card-detail.module').then( m => m.CardDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'payin',
    loadChildren: () => import('./pages/payin/payin.module').then( m => m.PayinPageModule),
    canActivate: [AuthGuard]
  }, 
  {
    path: 'transfer',
    loadChildren: () => import('./pages/transfer/transfer.module').then( m => m.TransferPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'card-view',
    loadChildren: () => import('./pages/card-view/card-view.module').then( m => m.CardViewPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'more',
    loadChildren: () => import('./pages/more/more.module').then( m => m.MorePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'payresult',
    loadChildren: () => import('./pages/payresult/payresult.module').then( m => m.PayresultPageModule),
    canActivate: [AuthGuard]
  },
   {
    path: 'resetpassword',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  }, 
 
   {
    path: 'admin-login',
    loadChildren: () => import('./pages/admin-pages/admin-login/admin-login.module').then( m => m.AdminLoginPageModule)
  },
  {
    path: 'admin-dashboard',
    loadChildren: () => import('./pages/admin-pages/admin-dashboard/admin-dashboard.module').then( m => m.AdminDashboardPageModule)
  },
  {
    path: 'cards',
    loadChildren: () => import('./pages/admin-pages/cards/cards.module').then( m => m.CardsPageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/admin-pages/users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'payments',
    loadChildren: () => import('./pages/admin-pages/payments/payments.module').then( m => m.PaymentsPageModule)
  },
  {
    path: 'log',
    loadChildren: () => import('./pages/log/log.module').then( m => m.LogPageModule)
  },
  {
    path: 'resetcode',
    loadChildren: () => import('./pages/resetcode/resetcode.module').then( m => m.ResetcodePageModule)
  },   








];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
