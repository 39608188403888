import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders, HttpParams } from '@angular/common/http';
import { User } from '../interfaces/user';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  public activeUser: User = null;

  constructor(private http: HttpClient) { }

  /**
   * Login an user with mail and password
   *
   * @param email The mail address of the user
   * @param password The password of the user
   * @returns The user with the given mail address and pasword. If not founs it's an emty answer
   */
  login(_email: string, _password: string) {
    localStorage.clear();
    return this.http.post<User>(
      `${environment.url}/fn-execute/login1`,
        {email:_email,password: _password},
        {
        headers : new HttpHeaders({"Content-Type": "application/json"})
         }
    ).pipe(
      tap(userResp => {
        let user = userResp;
        this.setUser(user);
        
      })
    );

    /*return this.getUserByEmailAndPwd(email, password).pipe(
      tap(users => {
        if (users.length !== 0) {
          
          this.setUser(users[0]);
        }
      })
    );*/
  }

  /*
   * logout an active user
   */
  logout(): void {
    this.activeUser = null;
    localStorage.clear();
  }

  isUserLoggedIn(): boolean {

    if (this.activeUser !== null) {
      return true;
    }

    const savedUser = localStorage.getItem('activeUser');

    if (savedUser !== null ) {
      this.activeUser = JSON.parse(savedUser);
      return true;
    }

    return false;
  }

  /**
   * Get a user from his ID
   *
   * @param id The ID of the user
   * @returns The user which belong to the ID
   */
  getUserById(id: string) {
    return this.http.get<User>(
      `${environment.url}/bucket/${environment.buckets.user}/data/${id}`
    );
  }

  /**
   * Create a new user
   *
   * @param user The new user
   * @returns The user which belong to the ID
   */
  createUser(user: User) {

    localStorage.clear();

    // remove user id. It must be set by the server
    // eslint-disable-next-line no-underscore-dangle
    delete user._id;

    return this.http.post<User>(
      `${environment.url}/bucket/${environment.buckets.user}/data`, user).pipe(
      tap(userResp => {
        if (Object.keys(userResp).length > 0) {
          this.setUser(userResp);
        }
      })
    );
  }
  //Sending the reset mail
  public resetPasswordMail(emailToSend: string){
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': 'http://localhost:8100'
    })
    const queryParams = new HttpParams().append('email',emailToSend);
    return this.http.get<boolean>(
      `${environment.url}/fn-execute/resetpassword1`,
        { headers: headers,
          params: queryParams},
    );
  }

  public checkCode(code){
    const queryParams = new HttpParams().append('token',code);
    return this.http.get<any>(
      `${environment.url}/fn-execute/checkcode`,
        {params: queryParams},
    );
  }

  checkEmail(user: User) {
    // remove user id. It must be set by the server
    // eslint-disable-next-line no-underscore-dangle
    let isLogin = false;
    user.name.length>1 ? isLogin = false : isLogin = true;

    return this.http.post<Boolean>(
      `${environment.url}/fn-execute/register1`,
        {email:user.email,password: user.password, name : user.name, login: isLogin},
        {
        headers : new HttpHeaders({"Content-Type": "application/json"})
         }
    ).pipe(
      tap(userResp => {
        console.log(userResp);
        /*if (Object.keys(userResp).length > 0) {
          this.setUser(userResp);
        }*/
      })
    );
  }

  public resetPasswordSafe(userEmail,newPassword){
    return this.resetPassword(userEmail,newPassword);
  }
  private resetPassword(userEmail,newPassword){
    const body = {
      email: userEmail,
      password: newPassword,
    };
    return this.http.put<any>(`${environment.url}/fn-execute/resetidentifier1`,body);
  }

  public codeTimeOut(email){
    const queryParams = new HttpParams().append('email',email);
    return this.http.get<any>(
      `${environment.url}/fn-execute/timeoutcode`,
        {params: queryParams},
    );
  }

  private setUser(user: User) {
    this.activeUser = user;
    delete this.activeUser.password;
    localStorage.setItem('activeUser', JSON.stringify(this.activeUser));
  }

  /**
   * Get a user from his mail and password
   *
   * @param email The mail address of the user
   * @param password The password of the user
   * @returns The user with the given mail address and pasword. If not founs it's an emty answer
   */
  private getUserByEmailAndPwd(email: string, password: string) {
    return this.http.get<User[]>(
      `${environment.url}/bucket/${environment.buckets.user}/data/?filter={"email":"${email}","password":"${password}"}`
    );
  }
}

