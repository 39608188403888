import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private userService: UserService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = state.url;

    return this.checkLogin(url);
  }

  checkLogin(url: string): true | UrlTree {
    console.log('auth guard Url: ' + url);
    const user = this.userService.isUserLoggedIn();

    if (this.userService.isUserLoggedIn()) {
      if (url === '/login') {
        this.router.parseUrl('/dashboard');
      } else {
        return true;
      }
    } else {
      return this.router.parseUrl('/login');
    }
  }
}

