import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { take } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { User } from '../../interfaces/user';
import { timer, Subject } from 'rxjs';
import { map, takeUntil, takeWhile,finalize } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  counter = 120;
  private stop$ = new Subject<any>();

    public value_: number = 0;
    public buffer: number = this.value_;

    timer$ = timer(0, 1000).pipe(
      takeUntil(this.stop$),               // stop when `stop$` emits
      takeWhile(_ => this.value_ < 1),     // are you sure `this.value_ == 1` is right?
      finalize(() => this.activateLoginFailedCode()),   // run this method when observable completes
      map(_ => {
        this.counter = this.counter-1
        this.value_ = this.value_ + 0.00833334;
        return this.value_;
      }
    ));
  /** Show login or register form */
  showRegister = false;
  formDataLogin: FormGroup;
  formDataRegister: FormGroup;
  formDataReset: FormGroup;
  formDataResetCode: FormGroup;
  version = "1.0.4";
  showReset = false;
  showResetCode = false;

  constructor(
    public toastController: ToastController,
    private router: Router,
    private userService: UserService
  ) { }

  get emailLogin() { return this.formDataLogin.get('email'); };
  get passwordLogin() { return this.formDataLogin.get('password'); };

  get nameRegister() { return this.formDataRegister.get('name'); };
  get emailRegister() { return this.formDataRegister.get('email'); };
  get passwordRegister() { return this.formDataRegister.get('password'); };
  get passwordRepeatLogin() { return this.formDataRegister.get('passwordrepeat'); };

  get resetPasswordEmail() {return this.formDataReset.get('emailreset');};

  get resetCodePassword() {return this.formDataResetCode.get('emailresetcode');};

  get newPassword() {return this.formDataResetCode.get('password');}
  get newPasswordRepeat() {return this.formDataResetCode.get('passwordrepeat');}

  
  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => {
    const pass = group.get('password').value;
    const confirmPass = group.get('passwordrepeat').value;
    return pass === confirmPass ? null : { notSame: true };
  };

  ngOnInit() {

    // LOGIN
    this.formDataLogin = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
      ]),
    });

    // REGISTER
    this.formDataRegister = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
      ]),
      passwordrepeat: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
      ]),
    });

    //RESET
    this.formDataReset = new FormGroup({
      emailreset: new FormControl('',[
        Validators.required,
        Validators.email
      ])
    });
    //RESET
    this.formDataResetCode = new FormGroup({
      emailresetcode: new FormControl('',[
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6)
      ]),
      password: new FormControl('',[
        Validators.required,
        Validators.minLength(5)
      ]),
      passwordrepeat: new FormControl('',[
        Validators.required,
        Validators.minLength(5),
      ])
    },this.checkPasswords);
  
  }
  goImpressum(){
    this.router.navigate(['impressum']);
  }
  goAGB(){
    this.router.navigate(['agb']);
  }
  goDatenschutz(){
    this.router.navigate(['datenschutz']);
  }
  async presentToast(msg: string) {
    const toastMessage = await this.toastController.create({
      message: msg,
      duration: 3000,
      color: 'danger'
    });
    toastMessage.present();
  }
  async presentToastOk(msg: string) {
    const toastMessage = await this.toastController.create({
      message: msg,
      duration: 3000,
      color: 'success'
    });
    toastMessage.present();
  }

  onSubmitLogin() {

    this.userService.login(this.emailLogin.value, this.passwordLogin.value).subscribe(users => {
        console.log(`Login user name: ${users.name}`);
        this.router.navigate(['dashboard']);
      
    }, err => {
      this.presentToast('Login nicht erfolgreich');
      console.log(err);
    });
  }

  onSubmitRegisterUser() {

    const user: User = {
      _id: '',
      name: this.nameRegister.value,
      email: this.emailRegister.value,
      password: this.passwordRegister.value,
      updated: new Date()
    };
    if (this.passwordRegister.value === this.passwordRepeatLogin.value) {

      this.userService.checkEmail(user).subscribe(isEmailThere => {

        if (isEmailThere) {
          this.presentToast('Das Anlegen des Benutzer war nicht erfolgreich');
        } else {
          this.presentToastOk('Das Anlegen des Benutzer war erfolgreich');
          // eslint-disable-next-line no-underscore-dangle
          //this.router.navigate(['login']);
          this.showRegister = false;
        }
      }, err => {
        console.log(err);
      });
        /*
      this.userService.createUser(user).subscribe(userResp => {

        if (Object.keys(userResp).length === 0) {
          this.presentToast('Das anlegen des Benutzer war nicht erfolgreich');
        } else {
          // eslint-disable-next-line no-underscore-dangle
          console.log(`Login user id: ${userResp._id}`);
          this.router.navigate(['dashboard']);
        }
      }, err => {
        console.log(err);
      });*/
    } else {
      this.presentToast('Die eingegebenen Passwörter stimmen nicht überein');
      //console.log('FUCKOFF');
    }

  }

  async sendResetEmail(){
    await this.userService.resetPasswordMail(this.resetPasswordEmail.value).subscribe(
      res=>{
      console.log('responnse:',res);
      this.presentToastOk('E-Mail gesendet');
      this.activateResetCode();
      },
    );
    //this.router.navigate(['resetpassword']);
  }

  async sendResetCode(){
    this.userService.checkCode(this.resetCodePassword.value).subscribe(
      res=>{
        //TODO
        //Go to the resetpassword page now
        if(res.status === true){
          this.resetPassword();
        }
        else{
          this.presentToast('Code stimmt nich bitte überprüfen');
        }
      },
      err=>{
        this.presentToast('Code stimmt nich bitte überprüfen')
      }
    )
  }

  resetPassword(){
    if(this.newPassword.value === this.newPasswordRepeat.value){
      try{
      const password = this.newPassword.value;
        this.userService.resetPasswordSafe(this.resetPasswordEmail.value,password).subscribe(
          res=>{
            this.presentToastOk(res.answer);
            this.activateLogin();
          },
          err=>{
            console.log(err.error.answer);
            this.presentToast(err.error.answer);
            
          }
        );
      }
      catch(err){
        this.presentToast('Link stimmt nicht, bitte überprüfen');
      }
    }
    else{
      this.presentToast('Passwörter sind nicht gleich');
      this.formDataResetCode.get('password').setValue('');
      this.formDataResetCode.get('passwordrepeat').setValue('');
    }
  }
  activateLoginFailedCode(){
    
    this.showRegister = false;
    this.showReset = false;
    this.showResetCode = false;
  }
  
  activateResetCode(){
    this.showResetCode = true;
    this.showReset = false
  }

  activateRegister() {
    this.showRegister = true;
  }

  activateReset() {
    this.showReset = true;
  }

  activateLogin() {
    this.showRegister = false;
    this.showReset = false;
    this.showResetCode = false;
  }
  goToAdminPage(){
    this.router.navigate(['admin-login']);
  }

}
