// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //url: 'http://109.70.96.42:4500/api',
  url: 'https://tmn.mynodes.cloud:4500/api',
  //ws_url: `wss://109.70.96.42:4500/api`,
  ws_url: 'https://tmn.mynodes.cloud:4500/api',
  buckets: {
    user: '630f572522714f002b35ec5b',
    card: '630f578e22714f002b35ec63',
    sale: '630f589c22714f002b35ec7e',
    payqueue: '63b7ddbe22714f002b36a69d',
  },
  apikey: '405gzp17l8arhu1d',
  app_version: '0.0.0'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
